import { RegionsGroupConfiguration } from './ac.config';

export class RegionGroupFakeBuilder {
  private code = 'eu';
  private name = 'Europe';

  withCode(code: string): this {
    this.code = code;
    return this;
  }

  withName(name: string): this {
    this.name = name;
    return this;
  }

  build(): RegionsGroupConfiguration {
    return {
      code: this.code,
      name: this.name,
    };
  }
}
