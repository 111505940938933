export interface TranslationsManifest {
  hashMap: Record<string, string>;
}

declare const TRANSLATIONS_PLACEHOLDER_MANIFEST: TranslationsManifest;

const DEFAULT_TRANSLATIONS_MANIFEST: TranslationsManifest = { hashMap: {} };

export const getTranslationsManifest = (): TranslationsManifest => {
  try {
    /**
     * It will be replaced by webpack plugin with calculated manifest containing translation hashes for each namespace, produced within build process.
     * LINK src/webpack/plugins/i18nCatalog/plugin.ts:30
     */
    return TRANSLATIONS_PLACEHOLDER_MANIFEST;
  } catch {
    return DEFAULT_TRANSLATIONS_MANIFEST;
  }
};
