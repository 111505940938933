import { AccordionSize, CardPattern, IconName } from '@ac/web-components';

import { PropsWithChildren } from '../../../../features/reactTransition';

import { AcAccordionProps } from './AcAccordionProps';
import { SimplifiedSectionDataType } from './config';
import { SimplifiedSectionProps } from './input';

const DEFAULT_ACCORDION_SIZE = AccordionSize.lg;

type AccordionShape = {
  accordionProps: AcAccordionProps;
  elements?: {
    summary?: JSX.Element;
    actions?: JSX.Element;
  };
};

export const getAccordionShape = ({
  config,
  presenter,
}: PropsWithChildren<SimplifiedSectionProps>): AccordionShape => {
  const { header, validationStatus } = config;
  const { isExpanded, isExpandable, isReadonly, isLoading, size } =
    presenter.state;

  const commonAccordionProps = {
    accordionTitle: header?.title ?? '',
    validationStatus,
    disabled: isReadonly,
    loading: isLoading,
    isExpandable,
    size: size ?? DEFAULT_ACCORDION_SIZE,
    pattern: isReadonly ? CardPattern.quaternary : CardPattern.secondary,
    'data-test-selector': config.testSelector,
  };

  if (config.type === SimplifiedSectionDataType.EmptyData) {
    return {
      accordionProps: {
        ...commonAccordionProps,
        icon: IconName.plus,
        isExpanded: false,
        onClickCallback: config.onAdd,
        pattern: CardPattern.quaternary,
      },
      elements: {
        actions: config.header?.actions,
      },
    };
  }

  if (config.type === SimplifiedSectionDataType.TextContent) {
    return {
      accordionProps: {
        ...commonAccordionProps,
        isExpanded: true,
      },
    };
  }

  if (!isExpandable) {
    return {
      accordionProps: {
        ...commonAccordionProps,
        onClickCallback: undefined,
        isExpanded,
      },
      elements: {
        actions: config.header?.actions,
      },
    };
  }

  return {
    accordionProps: {
      ...commonAccordionProps,
      isExpanded,
      onClickCallback: isExpanded ? presenter.collapse : presenter.expand,
    },
    elements: {
      summary: isExpanded ? undefined : config.header?.summary,
      actions: config.header?.actions,
    },
  };
};
