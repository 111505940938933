import {
  Config,
  ExternalUrls,
  RegionConfiguration,
  RegionsGroupConfiguration,
  SEPExternalFrontendUrls,
  SEPInternalFrontendUrls,
} from './ac.config';
import { RegionFakeBuilder } from './region.fakeBuilder';
import { RegionGroupFakeBuilder } from './regionGroup.fakeBuilder';

export class AcConfigFakeBuilder {
  private apiUrl = 'https://eu1.web.cicd.development.abovecloud.io/api-gateway';
  private containerFrontendUrl =
    'https://eu1.web.cicd.development.abovecloud.io';
  private identityOauthUrl =
    'https://eu1.identity.cicd.development.abovecloud.io"';
  private environmentType: Config['environmentType'] = 'dev';
  private environmentName = '';

  private newFrontendUrls: SEPInternalFrontendUrls = {
    accountsReceivable: '/accounts-receivable',
    activityConfiguration: '/activity-configuration',
    activityReservations: '/activity-reservations',
    allocationCalendar: '/allocation-calendar',
    availability: '/availability',
    cashiering: '/cashiering',
    centralReservationOffice: '/central-reservation-office',
    changelog: '/changelog',
    configuration: '/configuration',
    configurationv2: '/configurationv2',
    dataLake: '/data-lake',
    distributionChannelManager: '/distribution-channel-manager',
    floorPlan: '/floor-plan',
    golf: '/golf',
    housekeeping: '/housekeeping',
    individualReservations: '/individual-reservations',
    integrations: '/integrations',
    inventory: '/inventory',
    itinerary: '/itinerary',
    localhost: '/',
    meetingsAndEvents: '/meetings-and-events',
    notifications: '/notifications',
    profiles: '/profiles',
    rateManager: '/rate-manager',
    reports: '/reports',
    reservations: '/reservations',
    reservationsGroups: '/reservations-groups',
    resourceManagement: '/resource-management',
    taskManagement: '/task-management',
    travelAgentCommissions: '/travel-agent-commissions',
    workflows: '/workflows',
    globalFeatures: '/global-features',
  };

  private frontendUrls: SEPExternalFrontendUrls = {
    accountsReceivable: `${this.containerFrontendUrl}/accounts-receivable`,
    activityConfiguration: `${this.containerFrontendUrl}/activity-configuration`,
    activityReservations: `${this.containerFrontendUrl}/activity-reservations`,
    allocationCalendar: `${this.containerFrontendUrl}/allocation-calendar`,
    availability: `${this.containerFrontendUrl}/availability`,
    cashiering: `${this.containerFrontendUrl}/cashiering`,
    centralReservationOffice: `${this.containerFrontendUrl}/central-reservation-office`,
    changelog: `${this.containerFrontendUrl}/changelog`,
    configuration: `${this.containerFrontendUrl}/configuration`,
    configurationv2: `${this.containerFrontendUrl}/configurationv2`,
    dataLake: `${this.containerFrontendUrl}/data-lake`,
    distributionChannelManager: `${this.containerFrontendUrl}/distribution-channel-manager`,
    floorPlan: `${this.containerFrontendUrl}/floor-plan`,
    golf: `${this.containerFrontendUrl}/golf`,
    housekeeping: `${this.containerFrontendUrl}/housekeeping`,
    individualReservations: `${this.containerFrontendUrl}/individual-reservations`,
    integrations: `${this.containerFrontendUrl}/integrations`,
    inventory: `${this.containerFrontendUrl}/inventory`,
    itinerary: `${this.containerFrontendUrl}/itinerary`,
    localhost: `${this.containerFrontendUrl}/`,
    meetingsAndEvents: `${this.containerFrontendUrl}/meetings-and-events`,
    notifications: `${this.containerFrontendUrl}/notifications`,
    profiles: `${this.containerFrontendUrl}/profiles`,
    rateManager: `${this.containerFrontendUrl}/rate-manager`,
    reports: `${this.containerFrontendUrl}/reports`,
    reservations: `${this.containerFrontendUrl}/reservations`,
    reservationsGroups: `${this.containerFrontendUrl}/reservations-groups`,
    resourceManagement: `${this.containerFrontendUrl}/resource-management`,
    taskManagement: `${this.containerFrontendUrl}/task-management`,
    travelAgentCommissions: `${this.containerFrontendUrl}/travel-agent-commissions`,
    workflows: `${this.containerFrontendUrl}/workflows`,

    guestSelfService:
      'https://eu1.guest-self-service.cicd.development.abovecloud.io',
    housekeepingMobile:
      'https://eu1.housekeeping-mobile.cicd.development.abovecloud.io',
    registrationCard:
      'https://eu1.registration-card.cicd.development.abovecloud.io',
    systemMaintenance:
      'https://eu1.system-maintenance.cicd.development.abovecloud.io',
    translations: 'https://eu1.translations.cicd.development.abovecloud.io',
    userDocumentation:
      'https://eu1.user-documentation.cicd.development.abovecloud.io',
    worldMap: 'https://cicd.development.abovecloud.io',
  };

  private externalUrls: ExternalUrls = {
    contactSupport:
      // eslint-disable-next-line max-len
      'https://docs.shijigroup.com/bundle/Shiji_Enterprise_Platform_Contact_Details/page/CONTACT_DETAILS/concepts/c_shiji_enterprise_platform_contact_support.html',
  };

  private regions: RegionConfiguration[] = [
    new RegionFakeBuilder()
      .withCode('eu1')
      .withGlobal(true)
      .withName('Europe (Global)')
      .withRegionsGroupCode('eu')
      .build(),
    new RegionFakeBuilder()
      .withCode('eu2')
      .withGlobal(false)
      .withName('Europe 2')
      .withRegionsGroupCode('eu')
      .build(),
    new RegionFakeBuilder()
      .withCode('ap1')
      .withGlobal(false)
      .withName('Asia Pacific')
      .withRegionsGroupCode('ap')
      .build(),
    new RegionFakeBuilder()
      .withCode('cn1')
      .withGlobal(false)
      .withName('China')
      .withRegionsGroupCode('cn')
      .build(),
    new RegionFakeBuilder()
      .withCode('us1')
      .withGlobal(false)
      .withName('United States')
      .withRegionsGroupCode('us')
      .build(),
  ];

  private regionsGroups: RegionsGroupConfiguration[] = [
    new RegionGroupFakeBuilder().withCode('eu').withName('Europe').build(),
    new RegionGroupFakeBuilder().withCode('cn').withName('China').build(),
    new RegionGroupFakeBuilder()
      .withCode('us')
      .withName('United States')
      .build(),
    new RegionGroupFakeBuilder()
      .withCode('ap')
      .withName('Asia Pacific')
      .build(),
  ];

  withApiUrl(apiUrl: string): this {
    this.apiUrl = apiUrl;

    return this;
  }

  withRegions(
    regions: RegionConfiguration[],
    regionsGroups: RegionsGroupConfiguration[]
  ): this {
    this.regions = regions;
    this.regionsGroups = regionsGroups;

    return this;
  }

  withModulePath(
    pathName: keyof SEPInternalFrontendUrls,
    relativePathValue: string
  ): this {
    this.newFrontendUrls[pathName] = relativePathValue;
    this.frontendUrls[
      pathName
    ] = `${this.containerFrontendUrl}/${relativePathValue}`;
    return this;
  }

  withFrontendUrl(name: keyof SEPExternalFrontendUrls, url: string): this {
    this.frontendUrls[name] = url;
    return this;
  }

  withFrontendUrls(urls: SEPExternalFrontendUrls): this {
    this.frontendUrls = urls;
    return this;
  }

  withEnvironmentName(environmentName: string): this {
    this.environmentName = environmentName;
    return this;
  }

  build(): Config {
    return {
      containerFrontendUrl: this.containerFrontendUrl,
      apiUrl: this.apiUrl,
      frontendUrls: this.frontendUrls,
      newFrontendUrls: this.newFrontendUrls,
      identityOauthUrl: this.identityOauthUrl,
      regions: this.regions,
      regionsGroups: this.regionsGroups,
      environmentType: this.environmentType,
      externalUrls: this.externalUrls,
      environmentName: this.environmentName,
    };
  }
}
