import { getGlobalACPNamespace } from '../../../../../utils/globalAcpNamespace';

const appsCacheKey = 'appsCache';

const initializeCache = () => {
  const acpNamespace = getGlobalACPNamespace();
  acpNamespace[appsCacheKey] = {};
};

initializeCache();

export const clearAllCache = initializeCache;

interface AppsCache {
  [url: string]: Promise<string> | undefined;
}

const getAppsCache = () => getGlobalACPNamespace()[appsCacheKey] as AppsCache;

const fetchPage = async (appUrl: string) => {
  const response = await fetch(appUrl, {
    credentials: 'include',
    cache: 'reload',
  }).then((fileResponse) => {
    if (fileResponse.status < 200 || fileResponse.status >= 300) {
      throw new Error(`Unable to load ${appUrl}`);
    }

    return fileResponse.text();
  });

  return response;
};

export const loadPage = (appUrl: string): Promise<string> => {
  const appsCache = getAppsCache();
  const cachedPage = appsCache[appUrl];
  if (cachedPage) {
    return cachedPage;
  }

  const fetchingPage = fetchPage(`${appUrl}/index.html`);
  appsCache[appUrl] = fetchingPage;
  fetchingPage.catch(() => {
    appsCache[appUrl] = undefined;
  });

  return fetchingPage;
};
