import { acConfig } from '../declarations';
import {
  GlobalAcConfigSEPInternalFrontendUrls,
  getFrontendURL,
} from '../declarations/config/ac.config';
import { isStandalone } from '../utils/micro-frontends/isStandalone';
import { removeTrailingSlash } from '../utils/removeTrailingSlash';
import { getTranslationsManifest } from './manifest';
import { PathResolverConfig, TranslationsModuleName } from './types';

const REMOTE_TRANSLATIONS_DIRECTORY = 'i18n';
const EN_LANGUAGE_CODE = 'en';

const getFileName = (translationFilesNamePrefix: string, language: string) => {
  if (language === EN_LANGUAGE_CODE) {
    return `${EN_LANGUAGE_CODE}.json`;
  }

  const languageCode = language
    .toLowerCase()
    .replace(/-./, (value: string) => value.toUpperCase());
  return `${translationFilesNamePrefix}${languageCode}.json`;
};

const getHash = (namespace: string): string => {
  return getTranslationsManifest().hashMap[namespace] ?? '';
};

const getModuleBaseUrl = (moduleName: TranslationsModuleName): string => {
  return getFrontendURL(
    moduleName,
    acConfig.frontendUrls,
    acConfig.containerFrontendUrl,
    acConfig.newFrontendUrls as GlobalAcConfigSEPInternalFrontendUrls
  );
};

const getRemoteTranslationBaseUrl = (
  config: PathResolverConfig
): string | undefined => {
  return `${acConfig.frontendUrls.translations}/${REMOTE_TRANSLATIONS_DIRECTORY}/${config.repositoryName}`;
};

const getStaticTranslationBaseUrl = (config: PathResolverConfig): string => {
  const { moduleName = 'localhost' } = config;

  if (isStandalone()) {
    return `${location.origin}/${config.localTranslationsDirectory}`;
  }

  return `${removeTrailingSlash(getModuleBaseUrl(moduleName))}/${
    config.localTranslationsDirectory
  }`;
};

export const initPathResolver = (config: PathResolverConfig) => {
  const {
    useRemoteTranslations,
    repositoryName,
    defaultNamespace,
    translationFilesNamePrefix = '',
  } = config;
  const staticTranslationsBaseUrl = getStaticTranslationBaseUrl(config);
  const remoteTranslationsBaseUrl = getRemoteTranslationBaseUrl(config);
  // process.env['NODE_ENV'] because rollup plugin replaces `process.env.NODE_ENV` with constants
  const isProduction = process.env['NODE_ENV'] === 'production';

  return (languages: string[], namespaces: string[] = []): string => {
    const [language] = languages;
    const [namespace] = namespaces;

    const isDefaultNS = !namespace || namespace == defaultNamespace;
    const nsPath = isDefaultNS ? '' : `/${namespace}`;
    const fileName = getFileName(translationFilesNamePrefix, language);
    const translationsBaseUrl =
      useRemoteTranslations && repositoryName && language !== EN_LANGUAGE_CODE
        ? remoteTranslationsBaseUrl
        : staticTranslationsBaseUrl;

    const resourcePath = `${translationsBaseUrl}${nsPath}/${fileName}`;
    const currentNamespace = namespace || defaultNamespace;

    return `${resourcePath}${
      isProduction && currentNamespace ? `?v=${getHash(currentNamespace)}` : ''
    }`;
  };
};
