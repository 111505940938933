import { acConfig } from '../../declarations';
import { Config } from '../../declarations/config';

export const setUpAcConfigMock = () => {
  const originalAcConfig = { ...acConfig };

  return {
    mockAcConfig: (newAcConfig: Partial<Config>) => {
      Object.keys(newAcConfig).forEach((acConfigKey) => {
        acConfig[acConfigKey] = newAcConfig[acConfigKey];
      });
    },

    resetAcConfig: () => {
      Object.keys(originalAcConfig).forEach((acConfigKey) => {
        acConfig[acConfigKey] = originalAcConfig[acConfigKey];
      });
    },
  };
};
