import { RegionConfiguration } from './ac.config';
import { RegionGroupFakeBuilder } from './regionGroup.fakeBuilder';

export class RegionFakeBuilder {
  private code = 'eu2';
  private domain = 'cicd.development.abovecloud.io';
  private global = false;
  private name = 'Europe 2';
  private regionsGroupCode = new RegionGroupFakeBuilder().build().code;

  withCode(code: string): this {
    this.code = code;
    return this;
  }

  withDomain(domain: string): this {
    this.domain = domain;
    return this;
  }

  withGlobal(global: boolean): this {
    this.global = global;
    return this;
  }

  withName(name: string): this {
    this.name = name;
    return this;
  }

  withRegionsGroupCode(regionsGroupCode: string): this {
    this.regionsGroupCode = regionsGroupCode;
    return this;
  }

  build(): RegionConfiguration {
    return {
      code: this.code,
      domain: this.domain,
      global: this.global,
      name: this.name,
      regionsGroupCode: this.regionsGroupCode,
    };
  }
}
